import BaseBean from "@/utils/BaseBean";

export interface IClientRepairChooseDataObj {
    utilInst:ClientRepairChooseUtil
    refMap:Map<string,any>
    otherParams:any
}
export default class ClientRepairChooseUtil extends BaseBean{
    public dataObj:IClientRepairChooseDataObj;

    constructor(proxy:any,dataObj:IClientRepairChooseDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
}